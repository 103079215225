import { Breadcrumb, Col, Row } from 'antd'
import { H1, H3Bold, TextSmall, TextXsmall } from '../shared/typography'

import { IRecipe } from './recipe.interface'
import { Link } from 'gatsby'
import { PrintPage } from '../shared/utils'
import React from 'react'
import { StyledContent } from '../layout/layout'
import styled from '@emotion/styled'
import ShareBtn from '../shared/shareBtn'
import ReactMarkdown from 'react-markdown'
import { GatsbyImage } from 'gatsby-plugin-image'
import useWindowWidth from '../shared/useWindowSize'

const StyledRow = styled(Row)`
  padding-top: 2rem;
  padding-left: 2rem;
  min-height: 500px;
  position: relative;
  padding-bottom: 100px;
  @media(max-width: 766px) {
    min-height: unset;
    padding-left: 1rem;
  }
`

const TopRow = styled(Row)`
  padding-top: 1rem;
`

const StyledImg = styled(GatsbyImage)`
  margin-right: 4rem;
  margin-left: 2rem;
  z-index: 50;
  border-radius: 3px;
  @media(max-width: 766px) {
    margin-right: 1rem;
    margin-left: 1rem;
  }
`

type Props = {
  recipe: IRecipe
}

const RecipeComponent: React.FC<Props> = ({ recipe }: Props) => {
  const width = useWindowWidth()

  return (
    <>
      <StyledContent style={{ paddingTop: '2rem' }}>
        <Breadcrumb separator=">" style={{ marginLeft: '2rem' }}>
          <Breadcrumb.Item>
            <Link to="/">Forside</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/opskrifter">Opskrifter</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link
              to={`/opskrifter/${recipe.overskrift
                .toLowerCase()
                .replace(/ /g, '-')
                .replace(/[^\w-]+/g, '')}/`}
            >
              {recipe.overskrift}
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <TopRow>
          <Col sm={24} md={11}>
            <StyledImg image={recipe.billeder[0].gatsbyImageData} alt={recipe.overskrift} />
            {width < 766 &&<Row justify="space-between" style={{ padding: '0 1rem' }}>
              <H1 style={{margin: 0}}>{recipe.overskrift}</H1>
              <Col style={{paddingTop: '1rem'}}>
                <ShareBtn placement={'bottomRight'} title={recipe.overskrift} mediaUrl={recipe.billeder[0].file.url} margin="0 0.5rem 0 0" />
                <PrintPage />
              </Col>
            </Row>}
            <StyledRow>
              <Col span={24}>
                <H3Bold>Ingredienser</H3Bold>
                {recipe.ingredienser &&
                  recipe.ingredienser.map((ingredient, key) => {
                    return (
                      <Row key={key}>
                        <div style={{ alignSelf: 'center', display: 'flex', width: '100%' }}>
                          <TextSmall style={{ margin: '0', width: '50%' }}>{ingredient.ingrediens}</TextSmall>
                          <TextSmall style={{ margin: '0', width: '50%' }}>{ingredient.mngde}</TextSmall>
                        </div>
                      </Row>
                    )
                  })}
              </Col>
            </StyledRow>
          </Col>
          <Col sm={24} md={13}>
            {width >= 766 &&<Row justify="space-between" style={{ paddingRight: '1rem' }}>
              <Col span={20}>
                <H1>{recipe.overskrift}</H1>
                <TextXsmall><i>{recipe.oprettelseDato}</i></TextXsmall>
              </Col>
              <Col style={{ paddingTop: '1rem' }}>
                <ShareBtn placement={'bottomRight'} title={recipe.overskrift} mediaUrl={recipe.billeder[0].file.url} margin="0 0.5rem 0 0" />
                <PrintPage />
              </Col>
            </Row>}
            <div style={{ marginTop: '2rem' }}>
              <ReactMarkdown>{recipe.beskrivelse.beskrivelse}</ReactMarkdown>
            </div>
          </Col>
        </TopRow>
      </StyledContent>
    </>
  )
}

export default RecipeComponent
