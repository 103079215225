import {
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    PinterestIcon,
    PinterestShareButton,
    RedditIcon,
    RedditShareButton,
    TumblrIcon,
    TumblrShareButton,
    TwitterIcon,
    TwitterShareButton,
    ViberIcon,
    ViberShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from "react-share";
import { ShareIcon, isBrowser } from './utils';

import { Popover } from 'antd'
import React from 'react'
import { TooltipPlacement } from "antd/lib/tooltip";

type Props = {
    title: string
    placement: TooltipPlacement
    mediaUrl?: string
    margin?: string
}

const ShareBtn: React.FC<Props> = ({title, mediaUrl, placement, margin}: Props) => {

    const popoverContent = (
        <div>
            <FacebookShareButton title={title} url={isBrowser() ? window.location.href : ''}><FacebookIcon size={32} round={true} /></FacebookShareButton>
            <LinkedinShareButton title={title} url={isBrowser() ? window.location.href : ''}><LinkedinIcon size={32} round={true} /></LinkedinShareButton>
            {mediaUrl && <PinterestShareButton media={mediaUrl} title={title} url={isBrowser() ? window.location.href : ''}><PinterestIcon size={32} round={true} /></PinterestShareButton>}
            <RedditShareButton title={title} url={isBrowser() ? window.location.href : ''}><RedditIcon size={32} round={true} /></RedditShareButton>
            <TumblrShareButton title={title} url={isBrowser() ? window.location.href : ''}><TumblrIcon size={32} round={true} /></TumblrShareButton>
            <TwitterShareButton title={title} url={isBrowser() ? window.location.href : ''}><TwitterIcon size={32} round={true} /></TwitterShareButton>
            <ViberShareButton title={title} url={isBrowser() ? window.location.href : ''}><ViberIcon size={32} round={true} /></ViberShareButton>
            <WhatsappShareButton title={title} url={isBrowser() ? window.location.href : ''}><WhatsappIcon size={32} round={true} /></WhatsappShareButton>
        </div>
    )

    return (
    <Popover placement={placement} title={"Share"} content={popoverContent} trigger="click">
        <ShareIcon style={{margin: margin ? margin : 0}} src="/icons/share.svg" />
      </Popover>
    )
}

export default ShareBtn