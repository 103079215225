import { IRecipe } from '../components/recipes/recipe.interface'
import React, { Suspense, useState } from 'react'
import RecipeComponent from '../components/recipes/recipe-component'
import Seo from '../components/Seo'
import { graphql } from 'gatsby'
import useWindowWidth from '../components/shared/useWindowSize'
import styled from '@emotion/styled'
import { H2, H3, H3Bold, TextBold } from '../components/shared/typography'
import { Col, Row } from 'antd'
import ProductCard from '../components/shop/product-card'
import { IProduct } from '../components/shop/shop.interface'

const Container = styled.div`
  margin-top: 3rem;
  padding: 1rem;
  text-align: center;
`

type Props = {
  data: {
    recipe: { nodes: IRecipe[] }
    related: { nodes: IProduct[] }
  }
}

const Opskrift: React.FC<Props> = ({ data: { recipe, related } }: Props) => {
  return (
    <>
      <Seo
        title={recipe.nodes[0].overskrift}
        image={recipe.nodes[0].billeder[0].file.url}
        lang={'da'}
        description={recipe.nodes[0].beskrivelse.beskrivelse}
        date={recipe.nodes[0].oprettelseDato}
        author={'Jesper Mansted'}
      />
      <RecipeComponent recipe={recipe.nodes[0]} />

    {related && related.nodes.length > 0 &&
      <Row style={{ paddingTop: '2rem' }} justify="center">
        <H2 style={{ textAlign: 'center' }}>{`Vine som passer perfekt til denne opskrift`}</H2>
          {related.nodes.map((product, key) => (
            <Col xs={24} sm={12} md={8} lg={6} key={key}>
              <ProductCard product={product} />
            </Col>
          ))}
      </Row>}
      <Container>
        <H3Bold>Tilmeld dig nyhedsbrevet</H3Bold>
        <TextBold style={{ marginBottom: '2rem' }}>Tilmeld nyhedsbrevet i dag og modtag spændende vinnyheder, opskrifter og gode tilbud</TextBold>
        <Suspense fallback={<div>Loading...</div>}>
          <div className="klaviyo-form-XkyuAL"></div>
        </Suspense>
      </Container>
    </>
  )
}
export default Opskrift

export const query = graphql`
  query ($slug: String!, $related: [String]) {
    recipe: allContentfulOpskrift(filter: { overskrift: { eq: $slug } }) {
      nodes {
        overskrift
        oprettelseDato(formatString: "MMMM DD, YYYY")
        beskrivelse {
          beskrivelse
        }
        billeder {
          gatsbyImageData(width: 1200, placeholder: BLURRED)
          file {
            url
          }
        }
        ingredienser {
          mngde
          ingrediens
        }
        tilhrendeVine
      }
    }
    related: allShopifyProduct(
      filter: {shopifyId: {}, storefrontId: {in: $related}}
    ) {
      nodes {
        descriptionHtml
        description
        variants {
          storefrontId
          inventoryQuantity
          availableForSale
          compareAtPrice
          price
          inventoryQuantity
          selectedOptions {
            name
            value
          }
          image {
            gatsbyImageData
            originalSrc
          }
        }
        metafields {
          value
          key
        }
        featuredImage {
          originalSrc
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED, width: 600, formats: WEBP)
        }
        priceRangeV2 {
          maxVariantPrice {
            amount
            currencyCode
          }
          minVariantPrice {
            amount
            currencyCode
          }
        }
        vendor
        title
        collections {
          title
          metafields {
            value
          }
        }
      }
    }
  }
`